<template>
  <div class="grid">
    <div class="col-12 xl:col-12">
      <div class="card">
        <h5>Faturas para auditoria</h5>
        <DataTable
          ref="rf"
          exportFilename="relatorio[Faturas Aprovadas]"
          csvSeparator=";"
          :value="dadosFaturasParaAuditoria"
          :stickyHeader="true"
          dataKey="id_importacao_aprovada"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} de {last} para {totalRecords} resultado(s)"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar" />
              </span>
            </div>
          </template>

          <Column
            v-for="col of cabecalho"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            style="white-space: nowrap"
          >
            <template #body="slotProps">
              <span v-if="col.field === 'file_path'">
                <a :href="slotProps.data.file_path" target="_blank">Exibir Fatura</a>
              </span>
              <span v-else-if="col.field === 'acoes'">
                <div style="display: flex; gap: 0.5rem;">
                  <Button
                    label="Aprovar"
                    class="p-button-success p-button-rounded"
                    :disabled="slotProps.data.status_auditoria !== 'pending'"
                    @click="abrirConfirmacao('approve', slotProps.data)"
                  />
                  <Button
                    label="Reprovar"
                    class="p-button-danger p-button-rounded"
                    :disabled="slotProps.data.status_auditoria !== 'pending'"
                    @click="abrirConfirmacao('reject', slotProps.data)"
                  />
                </div>
              </span>
              <span v-else-if="col.field === 'status_envio'">
                {{ traduzirStatusEnvio(slotProps.data.status_envio) }}
              </span>
              <span v-else-if="col.field === 'status_auditoria'">
                {{ traduzirStatusAuditoria(slotProps.data.status_auditoria) }}
              </span>
              <span v-else>
                {{ slotProps.data[col.field] }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>

    <Dialog
      v-model:visible="confirmacaoModal"
      :style="{ width: '400px' }"
      modal
      header="Confirmação"
    >
      <div>
        <p v-if="acaoConfirmada === 'approve'">
          Tem certeza que pretende aprovar o pagamento?
        </p>
        <p v-else-if="acaoConfirmada === 'reject'">
          Tem certeza que deseja reprovar o pagamento?
        </p>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-secondary p-button-sm"
          @click="cancelarConfirmacao"
        />
        <Button
          label="Confirmar"
          icon="pi pi-check"
          class="p-button-success p-button-sm"
          @click="confirmarAcao"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dadosFaturasParaAuditoria: [],
      filters: {
        global: { value: null }
      },
      cabecalho: [
        { header: "ID Fatura", field: "fatura_id" },
        { header: "Fatura", field: "file_path" },
        { header: "Valor", field: "valor" },
        { header: "Status Auditoria", field: "status_auditoria" },
        { header: "Ações", field: "acoes" }
      ],
      upload: false,
      arquivo: null,
      confirmacaoModal: false,
      acaoConfirmada: '', // 'approve' ou 'reject'
      selectedImportacao: null,
    };
  },
  mounted() {
    this.listaFaturasAuditoria();
  },
  methods: {
    listaFaturasAuditoria() {
      this.axios
        .get("/fatura/listar-faturas-para-auditoria")
        .then((response) => {
          this.dadosFaturasParaAuditoria = response.data.dados;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Falha ao carregar os dados!",
            life: 3000
          });
        });
    },
    abrirConfirmacao(acao, registro) {
      this.selectedImportacao = registro;
      this.acaoConfirmada = acao;
      this.confirmacaoModal = true;
    },
    cancelarConfirmacao() {
      this.confirmacaoModal = false;
    },
    confirmarAcao() {
      const statusAuditoria = this.acaoConfirmada === 'approve' ? 'approved' : 'rejected';
      this.axios
        .post("/fatura/auditar-auditoria", {
          id_envio_pagamento: this.selectedImportacao.id_envio_pagamento,
          id_fatura: this.selectedImportacao.fatura_id,
          tipo_importacao: this.selectedImportacao.tipo_importacao,
          fornecedor_id: this.selectedImportacao.fornecedor_id,
          file_path: this.selectedImportacao.file_path,
          status_auditoria: statusAuditoria
        })
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: `Fatura ${statusAuditoria === 'approved' ? 'aprovada' : 'reprovada'} com sucesso!`,
            life: 3000
          });
          this.confirmacaoModal = false;
          this.listaFaturasAuditoria();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `Erro ao auditar a fatura.`,
            life: 3000
          });
          this.confirmacaoModal = false;
        });
    },
    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
    },
    enviarFatura() {
      if (!this.arquivo) {
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Selecione um arquivo antes de enviar.",
          life: 3000
        });
        return;
      }
      if (!this.selectedImportacao) {
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail: "Nenhum registro foi selecionado.",
          life: 3000
        });
        return;
      }
      const formData = new FormData();
      formData.append("arquivo", this.arquivo);
      formData.append("id_importacao_aprovada", this.selectedImportacao.id_importacao_aprovada);
      this.axios
        .post("/fatura/enviar-fatura", formData)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Arquivo enviado com sucesso",
            life: 3000
          });
          this.upload = false;
          this.listaFaturasAuditoria();
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao enviar o arquivo",
            life: 3000
          });
        });
    },
    exportCSV2() {
      this.$refs.rf.exportCSV();
    },
    traduzirStatusEnvio(status) {
      const map = {
        pending: "Pendente",
        sent: "Enviado"
      };
      return map[status] || status;
    },
    traduzirStatusAuditoria(status) {
      const map = {
        pending: "Pendente",
        approved: "Aprovado",
        rejected: "Rejeitado"
      };
      return map[status] || status;
    }
  }
};
</script>

<style scoped>
.tabela {
  margin: 20px 0;
}
.tabela th {
  white-space: nowrap;
  background: #eee;
  padding: 5px;
}
</style>
